<template>
  <div id="app" ref="container">
    <router-view/>
  </div>
</template>
<script>
    export default {
        name: "index",
        data() {
            return {
                // width: 1920, // 屏幕分辨率宽
                width: 1300, // 屏幕分辨率宽
                height: 800, // 屏幕分辨率高
                scale: 0 // 缩放比例
            };
        },
        created() {},
        mounted() {
            let _this = this;
            _this.setScale();
            window.onresize = function() {
                _this.setScale();
            };
        },
        methods: {
            // 得到呈现的屏幕宽高比
            getScale() {
                console.log(document.body.clientWidth );
                // 固定好16:9的宽高比，计算出最合适的缩放比，宽高比可根据需要自行更改
                const ww = document.body.clientWidth / this.width;
                const wh = window.innerHeight / this.height;/*
                const ww = window.innerWidth / this.width;
                const wh = window.innerHeight / this.height;*/
                // return ww < wh ? ww : wh;
                return ww;
            },

            // 设置网页缩放比例
            setScale() {
                if (document.body.clientWidth > 440) {
                    this.$refs.container.style.width = "100%";
                    this.$refs.container.style.transform = "inherit";
                    return false;
                }
                this.scale = this.getScale();
                this.$refs.container.style.width = this.width + "px";
                this.$refs.container.style.height = this.height + "px";
                this.$refs.container.style.transform = `scale(${this.scale}) `;
                this.$refs.container.style.WebkitTransform = `scale(${this.scale})`;
                //
                // this.$refs.container.style.transform = `scale(${this.scale}) translate(-7%,-50%)`;
                // this.$refs.container.style.WebkitTransform = `scale(${this.scale}) translate(-7%,-50%)`;

                /*
                this.$refs.container.style.transform = `scale(${
                    this.scale
                    }) translate(-50%,-50%)`;
                this.$refs.container.style.WebkitTransform = `scale(${
                    this.scale
                    }) translate(-50%,-50%)`;*/
            }
        }
    };
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
  #app{
    /*overflow: hidden;*/
    transform-origin: 0 0;
  }
</style>
