import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'
import LayoutDingZhi from '../layout/dingzhi-layout'
Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/homes',
    children: [
        {
            path: 'homes',
            name: 'home',
            component: () => import('../views/homes'),
            meta: {title: '首页', active: 'home'}
        }
    ]
  },
  {
    path: '/product-introduction',
    component: Layout,
    redirect: '/product-introduction/index',
    children: [
        {
            path: 'index',
            name: 'productIntroduction',
            component: () => import('../views/product-introduction'),
            meta: {title: '产品介绍', active: 'productIntroduction'}
        }
    ]
  },
  {
    path: '/enterprise-introduction',
    component: Layout,
    redirect: '/enterprise-introduction/index',
    children: [
        {
            path: 'index',
            name: 'enterpriseIntroduction',
            component: () => import('../views/enterprise-introduction'),
            meta: {title: '企业介绍', active: 'enterpriseIntroduction'}
        }
    ]
  },
  {
    path: '/field-introduction',
    component: Layout,
    redirect: '/field-introduction/index',
    children: [
        {
            path: 'index',
            name: 'fieldIntroduction',
            component: () => import('../views/field-introduction'),
            meta: {title: '领域介绍', active: 'fieldIntroduction'}
        }
    ]
  },
  {
    path: '/application-area',
    component: Layout,
    redirect: '/application-area/index',
    children: [
        {
            path: 'index',
            name: 'applicationArea',
            component: () => import('../views/application-area'),
            meta: {title: '应用领域', active: 'applicationArea'}
        },
        {
            path: 'solution',
            name: 'solution',
            component: () => import('../views/application-area/solution'),
            meta: {title: '解决方案', active: 'applicationArea'}
        }
    ]
  },
  {
    path: '/technical-service',
    component: Layout,
    redirect: '/technical-service/index',
    children: [
        {
            path: 'index',
            name: 'technicalService',
            component: () => import('../views/technical-service'),
            meta: {title: '技术服务', active: 'technicalService'}
        }
    ]
  },
  {
    path: '/procurement-guidelines',
    component: Layout,
    redirect: '/procurement-guidelines/index',
    children: [
        {
            path: 'index',
            name: 'procurementGuidelines',
            component: () => import('../views/procurement-guidelines'),
            meta: {title: '采购准则', active: 'procurementGuidelines'}
        }
    ]
  },
  {
    path: '/latest-consultation',
    component: Layout,
    redirect: '/latest-consultation/index',
    children: [
        {
            path: 'index',
            name: 'latestConsultation',
            component: () => import('../views/latest-consultation'),
            meta: {title: '最新咨询', active: 'latestConsultation'}
        },
        {
            path: 'consultation-info',
            name: 'consultationInfo',
            component: () => import('../views/latest-consultation/consultation-info'),
            meta: {title: '最新咨询', active: 'latestConsultation'}
        }
    ]
  },
  {
    path: '/customized',
    component: LayoutDingZhi,
    redirect: '/customized/index',
    children: [
        {
            path: 'index',
            name: 'customized',
            component: () => import('../views/customized'),
            meta: {title: '定制', active: 'customized'}
        }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
    scrollBehavior(to,from,saveTop){
      if(saveTop){
          return saveTop;
      } else {
          return {x:0,y:0}
      }
  }
});

export default router
