import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import "./styles/main.scss";
import * as url from './utils/url'
import axios from 'axios';
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'

// Vue.prototype.$video = Video;
Vue.prototype.$http = axios;
Vue.prototype.$url = url;
Vue.config.productionTip = false;
Vue.use(ElementUI, {
    size: 'small'
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
