<template>
  <!--<div ref="container">-->
  <div style="min-width: 1200px">
    <el-header class="header-container">
      <div class="header-content">
        <router-link to="/">
          <div class="header-left">
            <img src="../assets/svg/logo.svg" alt>
          </div>
        </router-link>
        <div class="header-right">
          <router-link v-for="(item, index) in menuList" :to="{path: item.url}" :key="index">
            <div
              class="menu-item"
              :class="{'active': item.active == $route.meta.active}"
            >{{item.name}}</div>
          </router-link>
        </div>
      </div>
    </el-header>
    <div class="bottom-container">
      <el-main class="main-container">
        <router-view/>
      </el-main>
      <el-footer class="footer-container">
        <div class="footer-infos">
          <div class="infos-div">
            <div class="footer-info-title">合作伙伴</div>
            <div class="footer-info-items">
              <div v-for="(item, index) in logosHezuo" :key="index">
                <a :href="item.officialUrl" target="_blok" class="huoban">
                  <img style="width: 100%" :src="item.imgUrl" alt>
                  <span style="padding-left:12px;">{{item.companyName}}</span>
                </a>
              </div>
            <!--  <div>
                <a href="http://www.hualian.biz" target="_blok" class="huoban">
                  <img src="../assets/icon/guanwang_bg_hezuofang_1.png" alt>
                  <span style="padding-left:12px;">华联机械</span>
                </a>
              </div>-->
            </div>
          </div>
          <div>
            <div class="footer-info-title">公司</div>
            <div class="footer-info-items">
              <router-link to="/product-introduction/index">
                <span style="color:#fff">产品介绍</span>
              </router-link>
              <router-link to="/enterprise-introduction/index">
                <span>企业介绍</span>
              </router-link>
              <router-link to="/application-area/index">
                <span>应用领域</span>
              </router-link>
              <router-link to="/procurement-guidelines/index">
                <span>采购准则</span>
              </router-link>
              <router-link to="/technical-service/index">
                <span>技术服务</span>
              </router-link>
              <router-link to="/latest-consultation/index">
                <span>最新资讯</span>
              </router-link>
            </div>
          </div>
          <div class="infos-div">
            <div class="footer-info-title">联系我们</div>
            <div class="footer-info-items">
              <div class="about-us">
                <img src="../assets/icon/guanwang_icon_zixundianhua_white.png" alt>
                <span>152-0577-8951</span>
              </div>
              <div class="about-us">
                <img src="../assets/icon/guanwang_icon_youjianzixun_white.png" alt>
                <span>958424773@qq.com</span>
              </div>
              <img src="../assets/image/weixin1.jpeg" width="80px" height="80px;" alt>
            </div>
          </div>
        </div>
        <div class="footers-text">
          <img src="../assets/fenge.png" alt>
        </div>
        <div class="footers-text"><a style="color: #fff" href="http://beian.miit.gov.cn">浙ICP备09107597号-8 </a></div>
      </el-footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      menuList: [
        { name: "首页", url: "/", active: "home" },
        {
          name: "产品介绍",
          url: "/product-introduction",
          active: "productIntroduction"
        },
        {
          name: "企业介绍",
          url: "/enterprise-introduction",
          active: "enterpriseIntroduction"
        },
        // {name: '领域介绍', url:'/field-introduction', active: 'fieldIntroduction'},
        {
          name: "应用领域",
          url: "/application-area",
          active: "applicationArea"
        },
        {
          name: "技术服务",
          url: "/technical-service",
          active: "technicalService"
        },
        {
          name: "采购准则",
          url: "/procurement-guidelines",
          active: "procurementGuidelines"
        },
        {
          name: "最新资讯",
          url: "/latest-consultation",
          active: "latestConsultation"
        }
      ],
      width: 1920, // 屏幕分辨率宽
      height: 1080, // 屏幕分辨率高
      scale: 0, // 缩放比例
        logosHezuo: []
    };
  },

  created() {
      this.getLogos();
      this.getBanners();
  },
  mounted() {
    let _this = this;
    /* window.onresize = function() {
      _this.setScale();
    };*/
    //  window.onload = function() {
    //   _this.setScale();
    // }
  },
  methods: {
      getBanners() {
          let url = this.$url.getBackendApiUrl(`/api/banner/list`);
          url = this.$url.addQueryString(url, "pageNum", 1);
          url = this.$url.addQueryString(url, "pageSize", 30);
          this.$http.post(url, {}).then(
              res => {
                  if (res.data.code == 200) {
                      const bannerObj = {};
                      res.data.data.list.forEach(item => {
                          bannerObj[item.type] = item;
                      });
                      localStorage.setItem("banners", JSON.stringify(bannerObj));
                  }
              },
              () => {}
          );
      },

      getLogos() {
          let url = this.$url.getBackendApiUrl(`/api/logo/list`);
          url = this.$url.addQueryString(url, 'pageNum', 1);
          url = this.$url.addQueryString(url, 'pageSize', 40);

          this.$http.post(url, {}).then(res => {
              if (res.data.code == 200) {
                  const result = res.data.data.list;
                  this.logosHezuo = result.filter(item => item.logoType == 2);
              }
          }, () => {
          });
      },

      // 得到呈现的屏幕宽高比
    getScale() {
      // 固定好16:9的宽高比，计算出最合适的缩放比，宽高比可根据需要自行更改
      // const width = 1920;
      // const height = 1080;
      const ww = window.innerWidth / this.width;
      const wh = window.innerHeight / this.height;
      return ww < wh ? ww : wh;
      // return ww < wh ? ww : wh;
    },

    // 设置网页缩放比例
    setScale() {
      this.scale = this.getScale();
      this.$refs.container.style.width = this.width + "px";
      this.$refs.container.style.height = this.height + "px";
      this.$refs.container.style.transform = `scale(${
        this.scale
      }) translate(-50%,-50%)`;
      this.$refs.container.style.WebkitTransform = `scale(${
        this.scale
      }) translate(-50%,-50%)`;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  height: 65px !important;
  line-height: 65px !important;
  background: #fff;
  display: flex;
  justify-content: center;
  /*position: fixed;*/
  /*left: 0;*/
  /*top: 0;*/
  width: 100%;
  .header-content {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-left {
      height: 35px;
    }
    .header-left img {
      height: 100%;
    }
    .header-right {
      display: flex;
      font-size: 14px;
      color: #000;
      .menu-item {
        padding: 0 6px;
        margin-left: 25px;
        color: #000;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        &.active {
          color: #393fe3;
          position: relative;
        }
        &.active::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 15%;
          width: 70%;
          height: 2px;
          background-color: rgba(57, 63, 227, 1);
          /*background: #393FE3;*/
        }
      }
    }
  }
}

.main-container {
  text-align: left;
  padding: 0;
  margin-bottom: 30px;
}
.footer-container {
  height: 370px !important;
  background-color: rgba(11, 16, 50, 1);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    background: url("../assets/shuiyin/fangan-white.png") right bottom no-repeat;
    opacity: 0.2;
    top: 0;
    right: 0;
    width: 400px;
    background-size: 400px;
    bottom: -151px;
    right: -155px;
    position: absolute;
    z-index: 0;
  }
  .footer-infos {
    width: 1200px;
    padding-top: 35px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .infos-div{
      width: 200px;
      margin-left: 20px;
    }
    .footer-info-title {
      font-size: 20px;
      font-family: PingFangSC-Semibold;
    }
    .footer-info-items {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      a {
        padding-bottom: 12px !important;
        span {
          color: rgba(255, 255, 255, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-Medium;
        }
      }
      .huoban {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .about-us {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        span {
          padding-left: 10px;
        }
      }
    }
  }
  .footers-text {
    text-align: center;
    color: #fff;
    img {
      width: 900px;
      padding: 15px 0;
    }
  }
}
</style>