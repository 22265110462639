<template>
    <div style="min-width: 1200px">
        <el-header class="header-container">
            <div class="header-content">
                <router-link to="/">
                    <!--<div class="header-left"><img src="../assets/svg/logo.svg" alt=""></div>-->
                </router-link>
            </div>
        </el-header>
        <div class="bottom-container">
            <div class="main-container">
                <router-view/>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "dingzhi-layout",
        data() {
            return {
            };
        },
    }
</script>

<style lang="scss" scoped>
    .header-container{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 99;
        height: 65px !important;
        line-height: 65px !important;
        background: #fff;
        display: flex;
        justify-content: center;
        box-shadow: 0px 1px 0px 0px rgba(233, 233, 238, 1);
        width: 100%;
        .header-content{

            width: 1200px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-left{
                height: 35px;
            }
            .header-left img{
                height: 100%;
            }
        }
    }
    .main-container{
        /*min-height: 1000px;*/
        text-align: left;
        padding: 0;
        /*margin-bottom: 30px;*/
    }
</style>