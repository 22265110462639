export function getBackendApiUrl(url) {
	return url;
}

export function addQueryString(url, param, val) {
	if (url.indexOf('?') > 0) {
		url += "&" + param + "=" + val;
	} else {
		url += "?" + param + "=" + val;
	}
	return url;
}

export var  typeListObj = {
    1: "化工",
    2: "塑料",
    3: "建材",
    4: "农肥",
    5: "饲料",
    6: "食品",
    7: "其他用途"
};
import shouyeImg from '../assets/banner/shouye_bg_banner.min.png';
import productImg from '../assets/banner/chanpin_bg_banner.min.jpg';
import qiYeJieShaoImg from '../assets/banner/qiyejieshao_bg_banner.min.jpg';
import lingYuImg from '../assets/banner/lingyu_bg_banner.min.jpg';
import jishuImg from '../assets/banner/jishufuwu_bg_banner.min.jpg';
import caigouImg from '../assets/banner/caigou_bg_banner.min.jpg';
import ziXunImg from '../assets/banner/zixun_bg_banner.min.jpg';

export var  backImg = {
    1: shouyeImg, // 首页
    2: productImg, // 产品介绍
    3: qiYeJieShaoImg, // 企业介绍
    4: lingYuImg, // 应用领域
    5: jishuImg, // 技术服务
    6: caigouImg, // 采购标准
    7: ziXunImg // 最新资讯
};

